<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Service Categories/List</h4>
          <div>
            <button :disabled="!can('service-category-create')"
                    :title="can('service-category-create') ? 'Create' : 'Create Forbidden'"
                    class="btn"
                    data-toggle="modal"
                    data-target="#serviceCategoryCreateModal">
              <span class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </button>
          </div>
        </div>
        <div class="users-list-filter px-1">
          <div class="row border rounded py-2 mb-2 align-items-center">
            <div class="col-12 col-sm-12 col-md-3">
              <label>Show Per Page</label>
              <VueMultiselect v-model="selectedPagination" :options="paginationList" :close-on-select="true"
                              label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
            </div>
            <div class="col-12 col-sm-12 col-md-6 mb-1 mb-sm-0">
              <label for="search">Search</label>
              <input v-model="getServiceCategoriesParams.name" id="search" class="form-control search-product-input-element"
                     type="text" placeholder="Search a category..."/>
            </div>
            <div class="col-12 col-sm-12 col-md-3 mb-1 mb-sm-0">
              <button @click="applyCustomFilter(null)" class="btn btn-primary btn-block glow users-list-clear mb-0">
                Filter
              </button>
            </div>
          </div>
        </div>
        <div class="users-list-table" ref="serviceCategoriesListRef">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{ width: '20%' }">NAME</th>
                    <th class="position-relative" :style="{ width: '20%' }">IMAGE</th>
                    <th class="position-relative" :style="{ width: '30%' }">DESCRIPTION</th>
                    <th class="position-relative" :style="{ width: '15%' }">Website Sort Order</th>
                    <th class="position-relative" :style="{ width: '5%' }">STATUS</th>
                    <th class="position-relative" :style="{ width: '10%' }">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(serviceCategory, index) in serviceCategories" :key="index">
                    <td>
                      {{ serviceCategory.name }}
                    </td>
                    <td>
                    <img style="height: 150px;" :src="serviceCategory?.image" alt="" srcset="">
                      
                    </td>
                    <td>
                      {{ serviceCategory.description }}
                    </td>
                    <td>
                      {{ serviceCategory.website_sort_order }}
                    </td>
                    <td>
                      <button :disabled="!can('service-category-update')"
                              @click="updateSingleServiceCategory(serviceCategory.id)"
                              :title="can('service-category-update') ?
                              (serviceCategory.status === 'Active' ? 'Active | Click to Inactive' : 'Inactive | Click to Active')
                              : 'Update Forbidden'"
                              :class="serviceCategory.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'"
                              class="btn badge">
                        {{ serviceCategory.status }}
                      </button>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-around">
                        <button :disabled="!can('service-category-update')"
                                :title="can('service-category-update') ? 'Update' : 'Update Forbidden'"
                                @click="getSingleServiceCategory(serviceCategory.id)"
                                class="btn text-decoration-none view-edit-delete-icon" >
                          <div class="" :style="{ height: '18px' }">
                            <i class="bx bx-edit"></i>
                          </div>
                        </button>
                        <button title="update image"   class="btn text-decoration-none view-edit-delete-icon" :disabled="!can('service-category-update')" @click="openImageUpdateModal(serviceCategory.id)">
                          <div class="" :style="{ height: '18px' }">
                            <i class='bx bx-image-add'></i>
                          </div>
                        </button>
                        <button :title="can('service-category-delete') ? 'Delete' : 'Delete Forbidden'"
                                :disabled="!can('service-category-delete')"
                                @click="this.modelIdForDelete = serviceCategory.id"
                                class="btn text-decoration-none cursor-pointer view-edit-delete-icon px-0"
                                data-toggle="modal"
                                data-target="#ServiceCategoryDeleteAlertModal">
                          <i class='bx bx-x'></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyCustomFilter"/>
              </div>
            </div>
          </div>
        </div>

        <div class="" data-toggle="modal" data-target="#serviceCategoryUpdateModal"></div>
        <div class="" data-toggle="modal" data-target="#ServiceCategoryStatusUpdate"></div>
        <div class="" data-toggle="modal" data-target="#updateAvatarModal"></div>

        <ServiceCategoryCreateModalVue/>
        <ServiceCategoryUpdateModalVue :currentServiceCategoryDetails="{...currentServiceCategoryDetails}"/>
        <ServiceCategoryStatusUpdate :currentServiceCategoryDetails="{...currentServiceCategoryDetails}"/>
        <ServiceCategoryDeleteAlertModal :model-id="modelIdForDelete" model-name="serviceCategory" modal-name="ServiceCategoryDeleteAlertModal"
                                 @confirmModelDeletion="confirmModelDeletion"/>
                                       <!-- for modal open dynamically -->
      
      <UpdateImageModal v-on:getSingleView="uploadImage($event)" :previousImage="currentServiceCategoryDetails?.image ?? defaultImage" />
      </section>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import ServiceCategoryCreateModalVue from "@/views/backEnd/serviceCategories/includes/ServiceCategoryCreateModal";
import ServiceCategoryUpdateModalVue from "@/views/backEnd/serviceCategories/includes/ServiceCategoryUpdateModal";
import ServiceCategoryStatusUpdate from "@/views/backEnd/serviceCategories/includes/ServiceCategoryStatusUpdate";
import ServiceCategoryDeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';
import UpdateImageModal from '@/views/backEnd/services/includes/ServiceImageUpdateModal.vue';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
// core packages
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ServiceCategoryList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ListPagination,
    ServiceCategoryCreateModalVue,
    ServiceCategoryUpdateModalVue,
    ServiceCategoryStatusUpdate,
    ServiceCategoryDeleteAlertModal,
    UpdateImageModal
  },

  data() {
    return {
      defaultImage: `${process.env.VUE_APP_API_URL}default/images/services/no-image.png`,
      modelIdForDelete: '',
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      getServiceCategoriesParams: {
        name: "",
        description: "",
        website_sort_order: "",
        status: "",
        order_by_name: "",
        order_by_website_sort_order: "DESC",
        paginate: 1,
        pagination: "",
        with_relation: [ 'media'],
      },
      currentServiceCategoryDetails: {},
    };
  },
  computed: {
    ...mapGetters({
      serviceCategories: "appServiceCategories/serviceCategories",
      serviceCategory: "appServiceCategories/serviceCategory",
      paginateLinks: "appServiceCategories/paginateLinks",
    }),
    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },
  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getServiceCategoriesParams.pagination = selectedPagination.value;
    },
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
      getServiceCategories: "appServiceCategories/getServiceCategories",
      deleteServiceCategoryOnList: 'appServiceCategories/deleteServiceCategoryOnList',
      putImageOnList: 'appServiceCategories/putImageOnList',
    }),
   async openImageUpdateModal(id) {
      this.currentServiceCategoryDetails = {};
      this.currentServiceCategoryDetails = {};
      this.currentServiceCategoryDetails = await this.serviceCategories.find((item) => item.id == id);
      document.querySelector('[data-target="#updateAvatarModal"]').click();
    },
    async uploadImage(imageBase64) {
      this.image = imageBase64;
      const payLoad = {
        id: this.currentServiceCategoryDetails.id,
        data: {
          image: imageBase64
        }
      }
      const response = await this.putImageOnList(payLoad);
      if (response.status === 201 || response.status === 200) {
        this.loader(false);
        document.querySelector('[data-target="#updateAvatarModal"]').click();
        
        this.getServiceCategoriesList();

      }
      if (response && response.message) {
        this.showToastMessage(response);
      }
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
    async getServiceCategoriesList() {
      this.loader(true);
      await this.getServiceCategories(this.getServiceCategoriesParams).then((response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          }
      );
      this.loader(false);
    },

    async applyCustomFilter(pageNumber) {
      await this.loader(true);
      this.getServiceCategoriesParams.page = pageNumber;
      await this.getServiceCategoriesList();
      await this.loader(false);
    },

    getSingleServiceCategory(id) {
      this.currentServiceCategoryDetails = {};
      this.currentServiceCategoryDetails = this.serviceCategories.find((item) => item.id == id);
      document.querySelector('[data-target="#serviceCategoryUpdateModal"]').click();


    },
    updateSingleServiceCategory(id) {
      this.currentServiceCategoryDetails = {};
      this.currentServiceCategoryDetails = this.serviceCategories.find((item) => item.id == id);
      document.querySelector('[data-target="#ServiceCategoryStatusUpdate"]').click();


    },

    async confirmModelDeletion(confirmModelDeletion) {
      await this.loader(true);
      await this.deleteSingleServiceCategoryOnList(confirmModelDeletion.modelId);
      await this.loader(false);
    },

    async deleteSingleServiceCategoryOnList(id) {
      await this.deleteServiceCategoryOnList(id).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Service Category Deleted",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      })
    }

  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getServiceCategoriesList();
    await this.loader(false);
  },
};
</script>

<style scoped>
</style>
