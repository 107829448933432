<template>
<div class="modal fade " id="ServiceCategoryStatusUpdate" tabindex="-1" role="dialog" aria-labelledby="ServiceCategoryStatusUpdate" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Service Categories</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
           
            <div class="modal-body">
                <div>
                    <form action="">
                       
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Status</label>
                                        <VueMultiselect v-model="serviceCategoryStatusCurrentValue" class="multiselect-blue" :options="serviceCategoryStatus" :close-on-select="true" placeholder="Select Type" label="name" track-by="value" :show-labels="false" />
                                    </div>
                                    <div v-if="errors.status !== undefined && errors.status.length > 0">
                                        <p v-for="(error,index) in  errors.status" :key="index" class="text-danger mb-1">
                                            {{ error }}
                                        </p>

                                    </div>

                                </div>

                            </div>

                        </div>
                        

                    </form>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button @click="updateServiceCategoryHandler" type="button" class="btn btn-primary update-todo px-4">Update </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
    mapActions,
} from 'vuex'
export default {
    props: ['currentServiceCategoryDetails'],
    
    mixins: [ShowToastMessage,Loader],
    name: "ServiceCategoryStatusUpdate",
    components: {
        VueMultiselect
    },
    mounted() {

    },
    data() {
        return {
          
            serviceCategoryName: '',
            serviceCategoryDescription: '',
            serviceCategoryStatusCurrentValue: '',
            serviceCategoryId: '',
            serviceCategoryStatus: [{
                    value: 0,
                    name: 'Inactive'
                },
                {
                    value: 1,
                    name: 'Active'
                },
            ],
            errors: {}

        }
    },
    computed: {
     
    },
    watch: {
        currentServiceCategoryDetails(currentServiceCategory) {
            this.serviceCategoryName = currentServiceCategory.name !== undefined ? currentServiceCategory.name : '';
            this.serviceCategoryDescription = currentServiceCategory.description !== undefined ? currentServiceCategory.description : '';
            this.serviceCategoryId = currentServiceCategory.id !== undefined ? currentServiceCategory.id : '';
            if (currentServiceCategory.status !== undefined) {
                this.serviceCategoryStatusCurrentValue = this.serviceCategoryStatus.find((status) => {
                    return status.name === currentServiceCategory.status;

                })
            } else {
                this.serviceCategoryStatusCurrentValue = ''
            }

        }
    },

    methods: {
        ...mapActions({
            putServiceCategoryOnList: 'appServiceCategories/putServiceCategoryOnList'
        }),
        async updateServiceCategoryHandler() {
            this.loader(true);
            const response = await this.putServiceCategoryOnList({
                id: this.serviceCategoryId,
                data: {
                    name: this.serviceCategoryName,
                    description: this.serviceCategoryDescription,
                    status: this.serviceCategoryStatusCurrentValue.value !== undefined ? this.serviceCategoryStatusCurrentValue.value : ''
                }
            })
            if (response.status === 200 || response.status === 201) {
                this.loader(false);
                this.errors = {};
                this.serviceCategoryName = '',
                this.serviceCategoryDescription = '',
                this.serviceCategoryStatusCurrentValue = '',
                document.querySelector('[data-target="#ServiceCategoryStatusUpdate"]').click();
                this.showToastMessage(response.message);
            } else {
                 this.loader(false);
                this.errors = response.errors;
            }
            if (response.message) {
                this.showToastMessage(response);
            }

        }
    },

}
</script>

<style>

</style>
