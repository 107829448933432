<template>
<div class="modal fade " id="serviceCategoryCreateModal" tabindex="-1" role="dialog" aria-labelledby="productDetailsModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Create Service Categories</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <form action="">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="form-group">
                                    <div class="">
                                        <label>Service category name</label>
                                        <input v-model="serviceCategoryName" type="text" class="form-control" placeholder="Enter service name" name="firstName">
                                    </div>
                                    <div v-if="errors.name !== undefined && errors.name.length > 0">
                                        <p v-for="(error,index) in  errors.name" :key="index" class="text-danger mb-1">
                                            {{ error }}
                                        </p>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Status</label>
                                        <VueMultiselect v-model="serviceCategoryStatusCurrentValue" class="multiselect-blue" :options="serviceCategoryStatus" :close-on-select="true" placeholder="Select Type" label="name" track-by="value" :show-labels="false" />
                                    </div>
                                    <div v-if="errors.status !== undefined && errors.status.length > 0">
                                        <p v-for="(error,index) in  errors.status" :key="index" class="text-danger mb-1">
                                            {{ error }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                          <div class="col-12 col-sm-12 col-md-6">
                            <div class="form-group">
                              <div class="">
                                <label>Website Sort Order</label>
                                <input v-model="websiteSortOrder" type="number" class="form-control" placeholder="Enter website sort order" name="websiteSortOrder">
                              </div>
                              <div v-if="errors.website_sort_order !== undefined && errors.website_sort_order.length > 0">
                                <p v-for="(error,index) in  errors.website_sort_order" :key="index" class="text-danger mb-1">
                                  {{ error }}
                                </p>

                              </div>

                            </div>

                          </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="form-group">
                                    <div class="">
                                        <label>Service category description</label>
                                        <textarea v-model="serviceCategoryDescription" class="form-control" name="" id="" cols="20" rows="10">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button @click="createServiceCategoryHandler" type="button" class="btn btn-primary update-todo px-4">Create Service Category</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
    mapActions,
   
} from 'vuex'
export default {
    mixins: [ShowToastMessage],
    name: "ServiceCategoryCreateModal",
    components: {
        VueMultiselect
    },
    data() {
        return {
            serviceCategoryName: '',
            websiteSortOrder: '',
            serviceCategoryDescription: '',
            serviceCategoryStatusCurrentValue: '',
            serviceCategoryStatus: [{
                    value: 0,
                    name: 'Inactive'
                },
                {
                    value: 1,
                    name: 'Active'
                },
            ],
            errors: {}

        }
    },
    methods: {
        ...mapActions({
            postServiceCategoryOnList: 'appServiceCategories/postServiceCategoryOnList'
        }),
        async createServiceCategoryHandler() {
            const response = await this.postServiceCategoryOnList({
                name: this.serviceCategoryName,
                website_sort_order: this.websiteSortOrder,
                description: this.serviceCategoryDescription,
                status: this.serviceCategoryStatusCurrentValue.value !== undefined ? this.serviceCategoryStatusCurrentValue.value : ''
            })
            if (response.status === 200 || response.status === 201) {
                this.errors = {};
                this.serviceCategoryName = '',
                this.websiteSortOrder = '',
                this.serviceCategoryDescription = '',
                this.serviceCategoryStatusCurrentValue = '',
                document.querySelector('[data-target="#serviceCategoryCreateModal"]').click();
                this.showToastMessage(response.message);
            } else {
                this.errors = response.errors;
            }
            if (response.message) {
                this.showToastMessage(response);
            }

        }
    },

}
</script>

<style>

</style>
